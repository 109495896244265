import React, { Component } from 'react';
import Animation from '../animation';
import portfolioAnimation from "../../static/animations/portfolio.json"

import { Grid } from "../../components/Grid/grid";
import IS_CORPORATE from '../../config/siteConfig';

class PortfolioContent extends Component {
    render() {
        const { data, text, subtitle,title } = this.props;
        let portfolioItems =  IS_CORPORATE ? data.edges:data.edges.filter(k=>k.node.data.show_on_ventures_page)
        return (
            <section id="portfolio">
                <div className="container wide gray">
                <div className="two-cols">
                    <div className="portfolio__animation">
                        <Animation animation={portfolioAnimation} />
                    </div>
                    <div>
                        <h2>{title}</h2>
                        <h3>{subtitle}</h3>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: text,
                            }}
                        />
                    </div>
                    </div>
                    <div className="portfolio__items">
                        {portfolioItems && (
                            <Grid className="grid--portfolio" onlyGrid={this.props.onlyGrid || false} data={portfolioItems} />

                        )}
                    </div>
                </div>
                
            </section>
        );
    }
}

export default PortfolioContent;